export const UNQUEUE_POOLS = {
  PRODUCTION: "prod",
  BETA: "beta",
  ALPHA: "alpha",
}

export const PAYMENT_METHODS = {
  CASH: "Cash",
  DEBIT_LINX: "Debit/Linx",
  CREDIT_CARD: "Credit Card",
}

export const PICKUP_METHODS = {
  IN_STORE: "Walkin",
  CURBSIDE: "Curbside",
  DELIVERY: "Delivery",
}

export const UNQUEUE_TIME_WINDOWS = {
  DAYTIME: "11am and 3pm",
  EVENING: "3pm and 7pm",
}

export const UNQUEUE_MARKET_TIME_WINDOWS = {
  DAYTIME: "8am and 10:30am",
  EVENING: "10:30am and 12pm",
}

export const SHOPPER_APP_STORE_URL =
  "https://apps.apple.com/tt/app/id1518497712"
export const SHOPPER_PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.unqueue.customer"
export const BUSINESS_APP_STORE_URL =
  "https://apps.apple.com/tt/app/id1518488244"
export const BUSINESS_PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.unqueue.business"

export const PAYMENT_PROCESSORS = {
  FAC: "FAC",
  WIPAY: "WIPAY",
}
export const KOS_COMPANY_ID =
  process.env.NODE_ENV === "development"
    ? "FBdbIxss6IT9TmiaUvnIAo17jLe2"
    : "DGRtdxSVYBMzx0VL3Xvx09nmx7l1" // "FBdbIxss6IT9TmiaUvnIAo17jLe2"
export const BUILD_INCREMENT = 2
export const NAMDEVCO_COMPANY_ID = "ckpouMAnzDX1Q7uoaH84kv1quvc2"
export const MISSING_ITEMS_PREFERENCES = {
  STORE_RECOMMENDATION: "Choose something of equal value for me",
  // CONTACT: "I’m not sure; please contact me",
  REFUND: "Refund the value of the item",
  STORE_CREDIT: "Give me store credit",
}
// Using an array to store the prices for different book quantities. Prices at index 0 are for 1 book, price at index 1 are for 2 books, etc.
// export const CANADA_ZONE_RATES = [211, 290, 385, 480, 480]
export const CANADA_ZONE_RATES = [
  {
    count: 1,
    priceUSD: 21,
    priceTTD: 145,
  },
  {
    count: 2,
    priceUSD: 42,
    priceTTD: 290,
  },
  {
    count: 3,
    priceUSD: 63,
    priceTTD: 434,
  },
  {
    count: 4,
    priceUSD: 84,
    priceTTD: 580,
  },
  {
    count: 5,
    priceUSD: 105,
    priceTTD: 725,
  },
]
// export const UK_ZONE_RATES = [251, 330, 425, 520, 520]
export const UK_ZONE_RATES = [
  {
    count: 1,
    priceUSD: 54.96,
    priceTTD: 380,
  },
  {
    count: 2,
    priceUSD: 109.92,
    priceTTD: 759,
  },
  {
    count: 3,
    priceUSD: 164.88,
    priceTTD: 1138,
  },
  {
    count: 4,
    priceUSD: 219.84,
    priceTTD: 1517,
  },
  {
    count: 5,
    priceUSD: 274.8,
    priceTTD: 1897,
  },
]
// export const USA_ZONE_RATES = [211, 290, 385, 480, 480]
export const USA_ZONE_RATES = [
  {
    count: 1,
    priceUSD: 14.25,
    priceTTD: 99,
  },
  {
    count: 2,
    priceUSD: 28.5,
    priceTTD: 197,
  },
  {
    count: 3,
    priceUSD: 42.75,
    priceTTD: 295,
  },
  {
    count: 4,
    priceUSD: 57,
    priceTTD: 394,
  },
  {
    count: 5,
    priceUSD: 71.25,
    priceTTD: 492,
  },
]
// export const SOUTH_AMERICA_ZONE_RATES = [274, 354, 449, 544, 544]
export const SOUTH_AMERICA_ZONE_RATES = [
  {
    count: 1,
    priceUSD: 60.52,
    priceTTD: 418,
  },
  {
    count: 2,
    priceUSD: 121.04,
    priceTTD: 836,
  },
  {
    count: 3,
    priceUSD: 181.56,
    priceTTD: 1253,
  },
  {
    count: 4,
    priceUSD: 242.08,
    priceTTD: 1670,
  },
  {
    count: 5,
    priceUSD: 302.6,
    priceTTD: 2088,
  },
]
// export const CARIBBEAN_ZONE_RATES = [227, 306, 401, 496, 496]
export const CARIBBEAN_ZONE_RATES = [
  {
    count: 1,
    priceUSD: 42.72,
    priceTTD: 295,
  },
  {
    count: 2,
    priceUSD: 85.44,
    priceTTD: 590,
  },
  {
    count: 3,
    priceUSD: 128.16,
    priceTTD: 885,
  },
  {
    count: 4,
    priceUSD: 170.88,
    priceTTD: 1180,
  },
  {
    count: 5,
    priceUSD: 213.6,
    priceTTD: 1474,
  },
]

export const TOBAGO_ZONE_RATES = [
  {
    count: 1,
    priceUSD: 5,
    priceTTD: 30,
  },
  {
    count: 2,
    priceUSD: 5,
    priceTTD: 30,
  },
  {
    count: 3,
    priceUSD: 5,
    priceTTD: 30,
  },
  {
    count: 4,
    priceUSD: 5,
    priceTTD: 30,
  },
  {
    count: 5,
    priceUSD: 5,
    priceTTD: 30,
  },
]
export const SA_REGION = [
  "Argentina",
  "Bolivia",
  "Brazil",
  "Chile",
  "Colombia",
  "Ecuador",
  "Guyana",
  "Panama",
  "Paraguay",
  "Peru",
  "Suriname",
  "Uruguay",
  "Venezuela",
]
export const UK_REGION = [
  "United Kingdom",
  "UK",
  "England",
  "Scotland",
  "Wales",
  "Ireland",
]
export const CARIBBEAN_REGION = [
  "Antigua and Barbuda",
  "Anguilla",
  "Bahamas",
  "The Bahamas",
  "Barbados",
  "Belize",
  "British Virgin Islands",
  "Canada",
  "Cayman Islands",
  "Costa Rica",
  "Cuba",
  "Dominica",
  "Dominican Republic",
  "El Salvador",
  "Grenada",
  "Guadeloupe",
  "Guatemala",
  "Haiti",
  "Honduras",
  "Jamaica",
  "Martinique",
  "Montserrat",
  "Mexico",
  "Nicaragua",
  "Panama",
  "Puerto Rico",
  "Saint Kitts and Nevis",
  "St Kitts & Nevis",
  "Saint Lucia",
  "St Lucia",
  "St Martin",
  "Saint Vincent and the Grenadines",
  "St Vincent and the Grenadines",
  "Suriname",
  "Turks and Caicos Islands",
  "USVI",
]
